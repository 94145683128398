import React from "react";
import styles from "./CommonQuestions.module.scss";

const CommonQuestions = () => {
  return (
    <div className={styles.textWrap}>
      <h2 className={styles.title}>FAQs</h2>
      <p className={styles.subtitle}>Where/When do I drop off samples?</p>
      <p className={styles.text}>
        Samples can be dropped off at or shipped to 6371 Fort Road Toppenish, WA
        98948. During harvest, we accept samples M-S from 7am to 5pm. Off-season
        samples are accepted M-F from 8am-4pm. If shipping samples, please
        contact us for shipping requirements.
      </p>
      <p className={styles.subtitle}>
        Are there any other tests offered that are not listed?
      </p>
      <p className={styles.text}>
        We do offer testing services not listed here. Please contact us to see
        if we can accommodate your testing needs.
      </p>
      <p className={styles.subtitle}>How do I submit samples?</p>
      <p className={styles.text}>
        Download our sample submission form. Enter your name, company, billing
        address and the contacts of anyone you would like to receive the emailed
        results. Each a la carte and package test have a Test Code, ex. S01,
        listed in the upper right-hand box of the form. Please list these codes
        under the Test columns for the tests that you’d like us to run. Include
        a hardcopy of this form with the samples when they are shipped or
        dropped off at our lab.
      </p>
    </div>
  );
};
export default CommonQuestions;
