import React from "react";
import { string, array } from "prop-types";
import { Link } from "gatsby";
import styles from "./List.module.scss";

const ListItem = ({ listTitle, price, content }) => {
  return (
    <li className={styles.listItem}>
      <div className={styles.box}>
        <span className={styles.label}>{listTitle}</span>
        <span className={styles.decor} />
        <span className={styles.price}>{price}</span>
      </div>
      <div className={styles.text}>{content}</div>
    </li>
  );
};

const List = ({ title, subtitle, listData, thingsChange, cta }) => {
  return (
    <section className={styles.wrap}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{subtitle}</p>
      <ul className={styles.list}>
        {listData.map(({ listTitle, price, content }, i) => {
          return (
            <ListItem
              key={i}
              listTitle={listTitle}
              price={price}
              content={content}
            />
          );
        })}
      </ul>

      {thingsChange && (
        <div className={styles.info}>
          Sometimes things change! HopTechnic® reserves the right to change
          prices without prior notifcation.
        </div>
      )}

      {cta && (
        // <Link target="_blank" href={`/Hoptechnic035_SampleSubmission_Excel_Rev08.pdf`}>
        <Link target="_blank" href={`/Hoptechnic035_SampleSubmission_Excel_Rev09.pdf`}>
          <button type="button" className={styles.button}>
            Download PDF
          </button>
        </Link>
      )}
    </section>
  );
};

List.propTypes = {
  title: string,
  subtitle: string,
  listData: array,
};

export default List;
