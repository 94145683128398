const testPackagesData = [
  {
    listTitle: "Comprehensive Brewing Assessment",
    price: "$675/batch of 4 samples",
    content:
      "Monitor flavor compounds from pre-fermentation to finished beer. This package offers free-thiol and terpene tracing for timepoints across the brewing process, including hop products, wort and finished beer.",
  },
  {
    listTitle: "Standard Hop Assessment (P01)",
    price: "$310/sample",
    content:
      "An enhanced package that includes everything from the comprehensive hop assessment but excludes the thiol fingerprinting.",
  },
  {
    listTitle: "Standard Thiol Hop Assessment (P04)",
    price: "$360/sample",
    content:
      "Entry level to tracking sulfur thiol fingerprints and quality control of your hops year over year.",
  },
  {
    listTitle: "Comprehensive Hop Assessment (P03) ",
    price: "$500/sample",
    content:
      "Spider chart aroma assessment to gain insight into sensory dynamics of your hop lots.",
  },
  {
    listTitle: "Aroma Assessment (P02)",
    price: "$115/lot",
    content: "Spider chart aroma assessment to gain insight into sensory dynamics of your hop lots.",
    list: [
      {
        itemTitle: "With Terpene Assessment",
        price: "$225/lot"
      },
      {
        itemTitle: "With Thiol Assessment",
        price: "$250/lot"
      },
      {
        itemTitle: "With Terpene and Thiol Assessment",
        price: "$400/lot"
      },
    ]
  },
  {
    listTitle: "Quantitative Hop Assessment (P05)",
    price: "$90/sample",
    content:
      "Basic brewing values, alpha/beta acids, moisture content and total oil distillation for hop samples.",
  },
  {
    listTitle: "Custom Project Work",
    price: "Please inquire",
    content: "",
  },
  {
    listTitle: "Harvest Timing Assessment ",
    price: "Please inquire",
    content: "",
  },
  {
    listTitle: "Field Monitoring",
    price: "Please inquire",
    content: "",
  },
];
const SingleHopData = [
  {
    listTitle: "Thiol Analysis (S07) ",
    price: "$215",
    content: "",
  },
  {
    listTitle: "Terpene Analysis (S04)",
    price: "$130",
    content: "",
  },
  {
    listTitle: "Terpene and Thiol Analysis (S08) ",
    price: "$235",
    content: "",
  },
  {
    listTitle: "Aroma Analysis (S06)",
    price: "$100",
    content: "",
  },
  {
    listTitle: "Alpha & Beta Acids (S01)",
    price: "$55",
    content: "",
  },
  {
    listTitle: "Total Oil (S03)",
    price: "$50",
    content: "",
  },
  {
    listTitle: "Iso-Alpha Acids (S02)",
    price: "$45",
    content: "",
  },
  {
    listTitle: "Moisture Content & Dry Matter Assessment (S05)",
    price: "$25",
    content: "",
  },
];
export { testPackagesData, SingleHopData };
