import React from "react";
import Main from "./components/main";
import Features from "./components/features";
import Form from "./components/form";
import styles from "./TruePellet.module.scss";
import List from "../test/components/list";
import { SingleHopData, testPackagesData } from "../test/testData";
import CommonQuestions from "../test/components/commonQuestions";

const TruePellet = () => {
  return (
    <div className={styles.containerScroll}>
      <div className={styles.container}>
        <List cta thingsChange title="" listData={[]} subtitle="" />
        <List
          title="A La Carte Services"
          subtitle="A la carte tests can be ordered as a single test or mixed-and-matched to meet your 
          specific testing needs. Please inquire for bulk testing costs. All prices are listed in US
          Dollar $(USD)."
          listData={SingleHopData}
        />
        <List
          title="Packages"
          subtitle="Brewing and hop related lab packages are listed below. Please inquire for bulk 
          discounts and custom services tailored to your specific needs. All prices are listed in
          US Dollar $(USD)."
          listData={testPackagesData}
        />
      </div>
      <CommonQuestions />
    </div>
  );
};

export default TruePellet;
