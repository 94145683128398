import React from "react";
import TruePellet from "../scenes/truePellet";
import SEO from "../components/seo";

export default () => {
  return (
    <>
      <SEO title={"Lab Services"} />
      <TruePellet />
    </>
  );
};
